.container{
  padding: 0 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.container>:nth-child(1)
{
  position: absolute;
  width: 8rem;
  left: 30%;
  top: -3rem;
}

.products{
  display: grid;
  width: 90%;
  grid-template-columns: 25% auto;
}
.menu{
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-weight: 500;
  font-size: 1.3rem;
}

.menu>li:hover{

  color: var(--pink);
  cursor: pointer;
}

.list{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  height: 25rem;
  overflow-y: scroll ;
  row-gap:2rem;
  column-gap: 2rem;
  justify-content: space-between;
}
.product{
  width: 12rem;
  height: 8rem;
  background-color: white;
  position: relative;
  overflow: hidden;
  padding: 1rem;
  display: flex;
  border-radius: 1rem;

  
}


.product>img{
   top: 3rem;
   width: 6rem;
   height: 11rem;
}
@media screen and (max-width: 856px){
  .container{
    gap: 1rem;
  }
  .container>:nth-child(1){
    display: none;
  }
  .products{
    grid-template-columns: none;
    gap: 1rem;
  }
  .menu{flex-direction: row;}
}

@media screen and (max-width: 640px){
  .menu{
    margin-left: -2rem;
    gap: 0.5rem;
    flex-wrap: wrap;
    width: 100%;
    font-size: 1.2rem;
  }
  .container{
    text-align: center;
    
  }
}